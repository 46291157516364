:root {
  --color-black: #173134;
  --color-white: hsl(0, 0%, 100%);
  --color-blueGrayDark: #507889;
  --color-blueGrayLight: #EDF4F6;
  --color-xLightGray: #F7F8F8;
  --color-lightGray: #E6E6E6;
  --color-gray: #999;
  --color-darkgray: #555;
  --color-brand: #00B26B; 
  --color-gradient: linear-gradient(270deg, #DAE2E8 0%, #DAE2E8 10%, #FFF 100%);
  --color-alert: #E5A800;

  --font-family: 'Nunito', sans-serif;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-size-xs: .75rem; /* 12px */
  --font-size-sm: .875rem; /* 14px */
  --font-size-md: 1rem; /* 16px */
  --font-size-lg: 1.125rem; /* 18px */
  --font-size-xl: 1.25rem; /* 20px */
  --font-size-xxl: 1.5rem; /* 24px */
  --font-size-2xl: 2rem; /* 32px */
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  text-rendering: geometricPrecision;
  background-color: var(--color-white);
}

body, button, input {
  font-family: 'Nunito', sans-serif;
}

.mainTitle {
  font-size: var(--font-size-lg);
  color: var(--color-blueGrayDark);
  margin: 0;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  body.overflow-hidden {
    overflow: hidden;

    #root {
      overflow: hidden;
      padding-top: 73px;
    }
  }
}